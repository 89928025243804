angular.module("app")
    .controller("departmentController", ["$scope", "$rootScope", "$state", "$log", "userService", function ($scope, $rootScope, $state, $log, userService) {

        var vm = this;

        vm.departments = undefined;
        vm.pageNotReady = vm.loading = true;


        vm.selectDepartment = selectDepartment;

        function init() {

            $rootScope.organisation = undefined;

            userService.getOrganisation()
                .then(function (result) {
                    $rootScope.organisation = result;

                    if(!_.isNil($rootScope.organisation.parentOrganisationReference)) {
                        $state.go('payment-methods');
                    }

                    vm.departments = _.map($rootScope.organisation.childOrganisations, function (department) {
                        var searchField = [];
                        searchField.push(department.name);
                        if (!_.isUndefined(department.abn)) searchField.push(' ' + department.abn);
                        if (!_.isUndefined(department.linkCode)) searchField.push(' ' + department.linkCode);

                        department.searchField = searchField.join('');

                        return department;
                    });

                    delete $rootScope.department;
                    localStorage.removeItem('department');

                })
                ['finally'](function () {
                vm.pageNotReady = false;
            });
        }

        function selectDepartment(department) {
            // if(_.isEqual(department, vm.selectedDepartment)) {
            //     vm.selectedDepartment = undefined;
            // } else {
            //     vm.selectedDepartment = department;
            // }

            $rootScope.department = department;
            localStorage.setItem('department', angular.toJson(department));
            $state.go("payment-methods");
        }

        init();
    }]);